.nr-main-list {
    ul {
        @include reset-list;
        li {
            border-bottom: dotted 1px $black;
            padding-bottom: 20px;
            margin-bottom: 20px;
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }
            a {
                display: block;
                color: $nr-color-one;
                @include media(">=xs") {
                    display: flex;
                }
                .media {
                    display: block;
                    overflow: hidden;
                    width: 100%;
                    height: 145px;
                    @include media(">=sm") {
                        width: 250px;
                        height: 150px;
                    }
                    .inner {
                        display: block;
                        height: 100%;
                        background-size: cover;
                        background-position: center center;
                        transition: all .5s ease-in-out;
                        transform: scale(1);
                    }
                }
                .editorial {
                    display: block;
                    width: 100%;
                    @include media(">=xs") {
                        width: auto;
                        padding-left: 20px;
                    }
                    .date {
                        display: inline;
                        font-size: rem(12);
                        font-family: $nr-condensed-font;
                        font-weight: 700;
                        text-transform: uppercase;
                        &:after {
                            content: ' | ';
                            font-size: rem(16);
                        }
                    }
                    .category {
                        display: inline;
                        text-transform: uppercase;
                        ul {
                            @include reset-list;
                            margin: 0;
                            padding: 0;
                            display: inline-block;
                            li {
                                display: inline-block;
                                text-align: left;
                                font-size: rem(12);
                                font-family: $nr-condensed-font;
                                font-weight: 700;
                                margin: 0;
                                padding: 0;
                                border-bottom: none;
                                &:after {
                                    content:', '
                                }
                                &:last-child {
                                    &:after {
                                        content: '';
                                    }
                                }
                            }
                        }
                    }
                    .title {
                        display: block;
                        font-size: rem(20);
                        font-weight: 700;
                        text-transform: uppercase;
					    font-family: $nr-condensed-font;
                    }
                    .detail {
                        display: block;
                        font-size: rem(14);
                        line-height: rem(16);
                        @include multiLineEllipsis($lineHeight: 1rem, $lineCount: 2, $bgColor: $white);  
                    }
                }
                &:hover {
                    color: $nr-color-two;
                    text-decoration: none;
                    .media {
                        .inner {
                            transition: all .5s ease-in-out;
                            transform: scale(1.25);
                        }
                    }
                }
                &:focus {
                    outline: dotted 2px $nr-color-one;
                }
            }   
        }
    }
}

.nr-sub-list {
    .category {
        font-family: $nr-header-font;
        font-size: rem(22);
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 20px;
    }
    ul {
        @include reset-list;
        li {
            border-bottom: dotted 1px $black;
            padding-bottom: 20px;
            margin-bottom: 20px;
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }
            a {
                display: block;
                color: $nr-color-one;
                text-decoration: none !important;
                @include media(">=xs") {
                    display: flex;
                }
                .media {
                    display: block;
                    overflow: hidden;
                    width: 100%;
                    height: 150px;
                    @include media(">=sm") {
                        width: 250px;
                        height: 150px;
                    }
                    .inner {
                        display: block;
                        height: 100%;
                        background-size: cover;
                        background-position: center center;
                        transition: all .5s ease-in-out;
                        transform: scale(1);
                    }
                }
                .editorial {
                    display: block;
                    width: 100%;
                    @include media(">=xs") {
                        width: auto;
                        padding-left: 20px;
                    }
                    .date {
                        display: inline;
                        font-size: rem(12);
                        font-family: $nr-condensed-font;
                        font-weight: 700;
                        text-transform: uppercase;
                        text-decoration: none;
                        &:after {
                            content: ' | ';
                            font-size: rem(16);
                        }
                    }
                    .category {
                        display: inline;
                        text-transform: uppercase;
                        ul {
                            @include reset-list;
                            margin: 0;
                            padding: 0;
                            display: inline-block;
                            li {
                                display: inline-block;
                                text-align: left;
                                font-size: rem(12);
                                font-family: $nr-condensed-font;
                                font-weight: 700;
                                margin: 0;
                                padding: 0;
                                border-bottom: none;
                                &:after {
                                    content:', '
                                }
                                &:last-child {
                                    &:after {
                                        content: '';
                                    }
                                }
                            }
                        }
                    }
                    .title {
                        display: block;
                        font-size: rem(20);
                        font-weight: 700;
                        text-transform: uppercase;
                        font-family: $nr-condensed-font;
                        text-decoration: underline !important;
                    }
                    .detail {
                        display: block;
                        font-size: rem(14);
                        line-height: rem(16);
                        @include multiLineEllipsis($lineHeight: 1rem, $lineCount: 2, $bgColor: $white);  
                    }
                }
                &:hover {
                    color: $nr-color-two;
                    text-decoration: none;
                    .media {
                        .inner {
                            transition: all .5s ease-in-out;
                            transform: scale(1.25);
                        }
                    }
                }
                &:focus {
                    outline: dotted 2px $nr-color-one;
                }
            }   
        }
    }
}
