.screen-reader-text {
    left: -9999em;
    position: absolute;
    top: -9999em;
    color: #fff;
}

ul.nr-sidebar-nav {
	list-style: none;
	background-color: $white;
	padding: 0;
	margin-bottom: rem(20);
	display: none;
	@include media(">=md") {
		display: block;
	}
	li {
		border-bottom: 2px solid $nr-nav-color-one;
		&:last-child {
			margin-bottom: 0;
		}
		a, a:visited {
			text-decoration: none;
			background-color: $white;
			color: $nr-nav-color-one;
			display: block;
			padding: 8px;
			font-weight: 700;
			font-family: $nr-condensed-font;
			font-size: rem(14);
			text-decoration: none !important;
			&:hover, &:active {
				background-color: $nr-nav-color-one-hover;
				color: $white;
			}
		}
		&.sidebar-has-sub {
			position: relative;
			a.toggle-sidebar-sub {
				background-color: transparent;
				color: $nr-nav-color-one;
				display: inline-block;
				font-size: inherit;
				line-height: inherit;
				padding: 7px;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 100; 
				height: 37px;
				border-bottom: none;
				&:hover {
					background-color: darken($nr-nav-color-one-hover, 10%);
					&:before {
						color: $white;
					}
				}
				&:before {
					display: inline-block;
					font-family: $nr-icon-font;
					font-weight: 700;
					content: '\f078';
					color: $nr-nav-color-one;
					transition: all 0.5s ease;
				}
				&.menu-visible {
					background-color: $nr-nav-color-two;
					&:before {
						display: inline-block;
						font-family: $nr-icon-font;
						font-weight: 700;
						content: '\f078';
						color: $white;
						transform: rotate(-180deg);
						transition: all 0.5s ease;
					}
					&:hover {
						background-color: darken($nr-nav-color-one-hover, 10%);
					}
					+ a {
						background-color: $nr-nav-color-one !important;
						color: $white !important;
					}
				}
			}
			a:nth-child(2)  {
				padding-right: 30px;
			}
		}
		ul {
			display: none;
			list-style: none;
			padding-left: 0;
			margin: 0;
			li {
				&.sidebar-has-sub {
					a.toggle-sidebar-sub {
						background-color: darken($nr-nav-color-one-hover, 10%); 
						&:before {
							color: $white;
						}
						&:hover {
							background-color: darken($nr-nav-color-one-hover, 15%); 
						}
						&.menu-visible {
							background-color: darken($nr-nav-color-one-hover, 10%); 
							&:hover {
								background-color: darken($nr-nav-color-one-hover, 15%); 
							}
						}
					}
				}
				a, a:visited  {
					background-color: $nr-xlt-grey; 
					&:hover, &:active {
						background-color: darken($nr-nav-color-one-hover, 10%); 
					}
				}
				ul {
					li {
						a, a:visited  {
							background-color: darken($nr-nav-color-one-hover, 10%); 
							color: $white;
							&:hover, &:active {
								background-color: darken($nr-nav-color-one-hover, 15%); 
							}
						}
						&:last-child {
							border-bottom: none;
						}
					}
				}
				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
}


.mob-sb-nav-trigger {
	position: relative;
	h2 {
		font-size: 20px;
		padding: 15px 0 0 60px;
		height: 50px;
		margin-bottom: 10px;
		@include media(">=md") {
			font-size: 24px;
			padding: 0;
			height: auto;
			margin-bottom: 40px;
			&:after {
				position: absolute;
				content: '';
				height: 5px;
				bottom: -20px; 
				margin: 0 auto;
				left: 0;
				width: rem(85);
				background: $nr-color-two;
			}
		}
	}
	a.sb-nav-trigger {
		background-color: $nr-nav-color-one;
		position: absolute;
		z-index: 3;
		top: 0;
		left: 0;
		height: 50px;
		width: 50px;
		overflow: hidden;
		white-space: nowrap;
		color: transparent;
		text-indent: -9999px;
		display: block;
		@include media(">=md") {
			display: none;
		}
		span {
			position: absolute;
			width: 22px;
			height: 2px;
			background-color: $white;
			left: 50%;
			top: 50%;
			bottom: auto;
			right: auto;
			-webkit-transform: translateX(-50%) translateY(-50%);
			-moz-transform: translateX(-50%) translateY(-50%);
			-ms-transform: translateX(-50%) translateY(-50%);
			-o-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);
			-webkit-transition: background-color 0.2s;
			-moz-transition: background-color 0.2s;
			transition: background-color 0.2s;
			&:before, &:after {
				/* top and bottom lines of the hamburger icon */
				position: absolute;
				width: 22px;
				height: 2px;
				background-color: $white;
				content: '';
				top: 0;
				left: 0;
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				-webkit-transition: -webkit-transform 0.2s;
				-moz-transition: -moz-transform 0.2s;
				transition: transform 0.2s;
			}
			&:before {
				-webkit-transform: translateY(-6px);
				-moz-transform: translateY(-6px);
				-ms-transform: translateY(-6px);
				-o-transform: translateY(-6px);
				transform: translateY(-6px);
			}
			&:after {
				-webkit-transform: translateY(6px);
				-moz-transform: translateY(6px);
				-ms-transform: translateY(6px);
				-o-transform: translateY(6px);
				transform: translateY(6px);
			}
		}
	}
	.sb-nav-trigger {
		&.nav-is-visible {
			span {
				background-color: transparent;
				&:before {
					-webkit-transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					-ms-transform: rotate(-45deg);
					-o-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}
				&:after {
					-webkit-transform: rotate(45deg);
					-moz-transform: rotate(45deg);
					-ms-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					transform: rotate(45deg);
				}
			}
		}
	}
}