$nr-color-one: #215732;
$nr-color-two: #002e5d;

$white: #fff;
$black: #000;
$nr-dk-grey: #333;
$nr-xlt-grey: #efefef;

$nr-categoryColor: $black;
$nr-categoryColorHover: $black;
$nr-categoryColorActive: $white;
$nr-categoryBgColor: transparent;
$nr-categoryBgColorHover: #efefef;
$nr-categoryBgColorActive: $nr-color-one;
$nr-border-radius: 20px;
$nr-nav-color-one: $nr-color-one;
$nr-nav-color-one-hover: $nr-color-two;
$nr-nav-color-two: $nr-color-two;
$nr-text-color: rgb(36, 36, 36);


/* Fonts */
// $nr-primary-font: 'Open Sans', sans-serif !default;

$nr-primary-font: 'news-gothic-std',sans-serif !default;
$nr-header-font: 'mrs-eaves',serif !default;
$nr-condensed-font: 'Open Sans Condensed', sans-serif !default;
$nr-serif-font: 'Open Sans', sans-serif!default;
$nr-sans-serif-font: 'Open Sans', sans-serif !default;
$nr-icon-font: 'Font Awesome 5 Pro';




// Slick Slider Inputs
$slick-font-path: "./fonts/" !default;
$slick-font-family: "Font Awesome 5 Pro" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: $white !default;
$slick-dot-color: $nr-color-one !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f053" !default;
$slick-next-character: "\f054" !default;
$slick-dot-character: "\f111" !default;
$slick-dot-size: 22px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
