// $white: $white;
// $black: $black;
// $nr-color-one: $main-color-one;
// $nr-color-two: $nr-color-two;
// $nr-categoryColor: $black;
// $nr-categoryColorHover: $black;
// $nr-categoryColorActive: $white;
// $nr-categoryBgColor: transparent;
// $nr-categoryBgColorHover: $nr-xlt-grey;
// $nr-categoryBgColorActive: $main-color-one;
// $nr-border-radius: 20px;
// $nr-nav-color-one: $nr-color-one;
// $nr-nav-color-one-hover: $nr-color-two;
// $nr-nav-color-two: $nr-color-two;


html {
    box-sizing: border-box;
  }

*, ::after, ::before, div {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

section {
    &.nr-featured {
        h2 {
            margin: 0 0 10px 0;
        }
    }
    &.nr-top-stories {
        h2 {
            margin: 60px 0 10px 0;
        }
    }
    &.nr-stories {
        h2 {
            margin: 60px 0 10px 0;
        }
        &.nr-sub-list {
            h2 {
                margin: 0;
            }
        }
    }
    &.nr-article {
        .article-head {
            h2 {
                margin: 0;
            }
        }
    }
}



@import 'newsroom_grid';
@import 'newsroom_article';
@import 'newsroom_buttons';
@import 'newsroom_categories';
@import 'newsroom_carousels';
@import 'newsroom_stories_lists';
@import 'newsroom_pagination';
@import 'newsroom_sidebar_nav';