.nr-container {
    max-width: rem(1400);
    margin: 0 auto;
    padding-right: .9375rem;
    padding-left: .9375rem;
}
.nr-grid {
    display: block;
    margin-left: rem(-10);
    margin-right: rem(-10);
    @include media(">=md") {
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin: 0;
        padding: 0;
        margin-left: rem(-15);
        margin-right: rem(-15);
    } 
    .nr-content {
        display: block;
        margin-left: rem(10);
        margin-right: rem(10);
        @include media(">=md") { 
            width: calc(80% - 1.875rem);
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            min-height: 0;
            min-width: 0;
            margin-left: rem(15);
            margin-right: rem(15);
            order: 1;
            &.full {
                width: 100%;
            }
        }
    }
    .nr-categories {
        display: block;
        margin-left: rem(10);
        margin-right: rem(10);
        @include media(">=md") { 
            width: calc(20% - 1.875rem);
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            min-height: 0;
            min-width: 0;
            margin-left: rem(15);
            margin-right: rem(15);
            order: 2;
        }
    }
    .nr-article {
        display: block;
        margin-left: rem(10);
        margin-right: rem(10);
        @include media(">=md") { 
            width: calc(80% - 1.875rem);
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            min-height: 0;
            min-width: 0;
            margin-left: rem(15);
            margin-right: rem(15);
            order: 1;
        }
    }
}

.nr-12 {
    display: block;
    margin-left: rem(10);
    margin-right: rem(10);
}

.nr-8 {
    display: block;
    margin-left: rem(10);
    margin-right: rem(10);
    @include media(">=md") { 
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        margin-left: rem(15);
        margin-right: rem(15);
        width: calc(66.66667% - 1.875rem);
    }
}

.nr-6 {
    display: block;
    margin-left: rem(10);
    margin-right: rem(10);
    @include media(">=md") { 
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        margin-left: rem(15);
        margin-right: rem(15);
        width: calc(50% - 1.875rem);
    }
}

.nr-4 {
    display: block;
    margin-left: rem(10);
    margin-right: rem(10);
    @include media(">=md") { 
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        margin-left: rem(15);
        margin-right: rem(15);
        width: calc(33.33333% - 1.875rem);
    }
}

.nr-controls {
    display: block;
    @include media(">=md") {
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin: 0;
        padding: 0;
    }
}

.text-center {
    text-align: center;
}